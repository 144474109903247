








































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';

import {
  Promotion,
  usePromo
} from '~/composables';

import {
  SfHeading,
  SfBreadcrumbs,
  SkeletonLoader,
  SfLoader
} from '~/components';

import PromosGrid from '../components/PromosGrid.vue';
import { getPromo } from '~/modules/promo/getters/promoGetters';
import PromoBanner from '~/modules/promo/components/PromoBanners/PromoBanner.vue';
import { usePageStore } from '~/stores/page';
import { usePromosStore } from '~/modules/promo/stores/promos';

export default defineComponent({
  name: 'promo',
  head() {
    return {
      title: this.$i18n.t('Promo') as string
    };
  },
  components: {
    PromoBanner,
    PromosGrid,
    SfHeading,
    SfBreadcrumbs,
    SkeletonLoader,
    SfLoader,
  },
  setup() {
    const { error: nuxtError } = useContext();
    const { loadPromo, loadingPromo } = usePromo();
    const { routeData } = usePageStore();
    const promotionId = computed(() => routeData.promotion_id);
    const promoStore = usePromosStore();

    const promo = ref<Promotion | null>(promoStore.promo);

    useFetch(async () => {
      if (promoStore.promo.title) {
        promo.value = promoStore.promo;
      } else {
        const result = await loadPromo(promotionId.value);
        promo.value = getPromo(result);

        if (Object.keys(promo.value).length === 0) {
          nuxtError({ statusCode: 404 });
        }

        promoStore.$patch((state) => {
          state.promo = promo.value;
        });
      }
    });

    return {
      promo,
      loadingPromo,
    };
  }
});
